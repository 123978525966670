import apiClient from 'services/apiClient'
import {
  RiskFilterIn,
  RiskContextRiskOverviewFilterIn,
  PolicyParameterRiskOverViewFilterIn,
} from 'typescript-axios'

export const getSupplierRisk = async ({ supplierId }: { supplierId: string }) => {
  const { data } = await apiClient.getV2Risk(supplierId)
  return data
}

export const getRiskDataEntries = async ({
  ids,
  page,
  pageSize,
}: {
  ids: string[]
  page: number
  pageSize: number
}) => {
  const { data } = await apiClient.getV2RiskDataEntries(ids, page, pageSize)
  return data
}

export const getRiskDataSource = async ({ id }: { id: string }) => {
  const { data } = await apiClient.getV2RiskDataSourcesId(id)
  return data
}

export const getRiskDataSources = async ({
  page,
  pageSize,
}: {
  page: number
  pageSize: number
}) => {
  const { data } = await apiClient.getV2RiskDataSources(page, pageSize)
  return data
}

export const getRiskMitigationRulesForParameters = async ({
  parameterIds,
  page,
  pageSize,
}: {
  parameterIds: string[]
  page: number
  pageSize: number
}) => {
  const { data } = await apiClient.getV2RiskMitigationRules(page, pageSize, parameterIds)
  return data
}

export const getRiskMitigationRule = async ({ id }: { id: string }) => {
  const { data } = await apiClient.getV2RiskMitigationRulesId(id)
  return data
}

export const getRiskObjective = async () => {
  const { data } = await apiClient.getV2RiskObjective()
  return data
}

export const getRisk = async (filter: RiskFilterIn) => {
  const { data } = await apiClient.postV2Risk(filter)
  return data
}

export const getOverviewRiskContext = async (
  riskContextRiskOverviewFilterIn: RiskContextRiskOverviewFilterIn
) => {
  const { data } = await apiClient.postV2RiskOverviewContext(riskContextRiskOverviewFilterIn)
  return data
}

export const getOverviewRiskParameters = async (
  policyParameterRiskOverViewFilterIn: PolicyParameterRiskOverViewFilterIn
) => {
  const { data } = await apiClient.postV2RiskOverviewPolicyParameters(
    policyParameterRiskOverViewFilterIn
  )
  return data
}
