import { useInfiniteQuery, useQuery, UseQueryOptions } from 'react-query'
import { ResourceListDataEntryV2ApiDto, ResourceFoundDataEntryV2ApiDto } from 'typescript-axios'
import { AxiosError } from 'axios'
import { getEntries, getEntry } from './api'
import queryKeys, { DataEntriesFilterProps } from './queryKeys'

// const applyScoringToDataEntry = (entry: DataEntryV2ApiDto) => {
//   const flatScorableRawEntries =
//     entry?.dataEntryValues?.filter((y): y is DataEntryPolicyParameterValueApiDto =>
//       Boolean('score' in y && y.score && 'min' in y.score)
//     ) || []

//   const averageScoreAcrossDataEntries =
//     flatScorableRawEntries && flatScorableRawEntries.length > 0
//       ? flatScorableRawEntries?.reduce((prev, curr) => {
//           if (!curr.score || !('min' in curr.score)) return prev
//           return prev + getAverageScore(curr.score.min, curr.score.max, true)
//         }, 0) / flatScorableRawEntries.length
//       : undefined
//   return {
//     ...entry,
//     overallScore: averageScoreAcrossDataEntries || 0,
//     overallLikertScale: getLikertScale(averageScoreAcrossDataEntries || 0, true, true, true),
//     dataEntryValues: entry.dataEntryValues?.map((x) => {
//       if ('score' in x && x.score && 'min' in x.score) {
//         return {
//           ...x,
//           score: {
//             ...x.score,
//             value: getAverageScore(x.score.min, x.score.max),
//             likertScale: getLikertScale(
//               getAverageScore(x.score.min, x.score.max),
//               true,
//               true,
//               true
//             ),
//           },
//         }
//       }
//       return x
//     }),
//   }
// }

const { dataEntriesPage, singleDataEntry } = queryKeys

export const useGetDataEntries = (filters: DataEntriesFilterProps, enabled?: boolean) => {
  return useInfiniteQuery<
    ResourceListDataEntryV2ApiDto,
    AxiosError,
    ResourceListDataEntryV2ApiDto,
    ReturnType<typeof dataEntriesPage>
  >(dataEntriesPage(filters), ({ pageParam = 1 }) => getEntries({ page: pageParam, ...filters }), {
    getNextPageParam: (lastPage) => lastPage.paging.nextPage,
    staleTime: Infinity,
    enabled,
    // select: (data) => {
    //   return {
    //     ...data,
    //     pages: data.pages.map((page) => {
    //       return {
    //         ...page,
    //         data: page.data?.map((entry) => {
    //           return applyScoringToDataEntry(entry)
    //         }),
    //       }
    //     }),
    //   }
    // },
  })
}

export const useGetDataEntry = (
  id: string,
  options?: UseQueryOptions<
    ResourceFoundDataEntryV2ApiDto,
    AxiosError,
    ResourceFoundDataEntryV2ApiDto,
    ReturnType<typeof singleDataEntry>
  >
) => {
  return useQuery(singleDataEntry(id), () => getEntry(id), {
    ...options,
    staleTime: Infinity,
    // select: (data) => {
    //   return {
    //     ...data,
    //     data: applyScoringToDataEntry(data.data),
    //   }
    // },
  })
}
