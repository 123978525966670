import { useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { Dialog, ParameterCategoryChip } from 'components'
import { useHistory } from 'react-router-dom'
import { ScorecardV2ApiDto } from 'typescript-axios'
import { useGetSuppliersCount } from 'hooks'
import { formatApiFiltersToList, useReadableFilters } from 'views/buyer/Scorecards/helpers'
import { ArrowForwardRounded as GoToArrow } from '@mui/icons-material'
import { Typography, Button } from '@mui/material'

const PREFIX = 'AboutSupplierScorecard'

const classes = {
  avatar: `${PREFIX}-avatar`,
  divider: `${PREFIX}-divider`,
  progressText: `${PREFIX}-progressText`,
  donutContainer: `${PREFIX}-donutContainer`,
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.avatar}`]: {
    backgroundColor: theme.palette.backgroundDark,
    color: theme.palette.common.black,
  },

  [`& .${classes.divider}`]: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },

  [`& .${classes.progressText}`]: {
    fontWeight: 600,
    color: theme.palette.text.primary,
  },

  [`& .${classes.donutContainer}`]: {
    width: 42,
    height: 42,
    marginLeft: theme.spacing(),
  },
}))

interface Props {
  open: boolean
  handleClose: () => void
  selectedScorecard: ScorecardV2ApiDto
  supplierName: string
}

const AboutSupplierScorecard = ({ open, handleClose, selectedScorecard, supplierName }: Props) => {
  const history = useHistory()

  const handleGoToScorecard = () => history.push(`/scorecards/${selectedScorecard.id}`)

  const filtersToList = useMemo(() => {
    return selectedScorecard.supplierFilterOperator
      ? formatApiFiltersToList(selectedScorecard.supplierFilterOperator)
      : []
  }, [selectedScorecard])

  const { data, error } = useGetSuppliersCount(filtersToList, {
    enabled: filtersToList.length > 0,
  })

  const readableFilters = useReadableFilters(filtersToList)

  // About ${scorecardName}
  // ${scorecardName} applies to ${supplierCount} suppliers based on ${filterCount} filters:
  // [...filters]
  // ${supplierName} is performing +-X% better than the average performance of the scorecard suppliers.

  // Close || Go to scorecard ->

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      withForm={false}
      withClose
      scroll="paper"
      maxWidth="xs"
      title={`About ${selectedScorecard.name}`}
      buttons={
        <span>
          <span>
            <Button variant="text" onClick={handleClose} color="primary">
              Close
            </Button>
            <Button
              variant="text"
              onClick={handleGoToScorecard}
              color="primary"
              endIcon={<GoToArrow />}
            >
              Go to scorecard
            </Button>
          </span>
        </span>
      }
    >
      <div>
        <Typography variant="body1" color="textSecondary" style={{ marginBottom: 4 }}>
          Suppliers
        </Typography>
        <Typography variant="body1" gutterBottom>
          {selectedScorecard.name} applies to {data || '...'} suppliers by utilizing these supplier
          filters:{' '}
        </Typography>
        {readableFilters.map((x) => {
          return (
            <ParameterCategoryChip
              key={x.id}
              label={`${x.property} ${x.condition} ${x.value.join(', ')}`}
            />
          )
        })}
      </div>
    </StyledDialog>
  )
}

export default AboutSupplierScorecard
