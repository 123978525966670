import { useState, memo } from 'react'
import { Grid, Typography } from '@mui/material'
import { Paper, PaperContent } from 'components'
import { useMixpanel } from 'services/mixpanel'
import { v4 as uuid } from 'uuid'
import { getAverageScore, getDataCoverage, usePermissionContext } from 'utils'
import { ScorecardV2ApiDto, ScorecardItemV2ApiDto } from 'typescript-axios'
import { QuestionWithAnswers } from 'views/buyer/Suppliers/SingleSupplier'
import Item from './components/Item'
import Cards from './components/Cards'

interface Props extends ScorecardV2ApiDto {
  /** If supplier context,  */
  handleOpenScorecardItem: (item: ScorecardItemV2ApiDto) => void
  /** For supplier, we show data entries inline. For suppliers, we show data coverage % */
  context?: 'supplier' | 'suppliers'
  children?: React.ReactNode
  questionsWithAnswers?: QuestionWithAnswers[]
}

export interface ItemProps {
  handleOpenScorecardItem: (item: ScorecardItemV2ApiDto) => void
  handleToggleExpanded: (id: string) => void
  isChild: boolean
  isExpanded: boolean
  item: ScorecardItemV2ApiDto
  id: string
  /** for supplier, show data source badges instead of bar */
  context?: 'supplier' | 'suppliers'
  parameterQuestions?: QuestionWithAnswers[]
  isNewScoringScaleOrg: boolean
}

const renderItem = ({
  isChild,
  item,
  id,
  isExpanded,
  handleOpenScorecardItem,
  handleToggleExpanded,
  context,
  parameterQuestions,
  isNewScoringScaleOrg,
}: ItemProps) => {
  return (
    <Item
      key={id}
      item={item}
      id={id}
      isChild={isChild}
      isExpanded={isExpanded}
      handleOpenScorecardItem={handleOpenScorecardItem}
      handleToggleExpanded={handleToggleExpanded}
      context={context}
      parameterQuestions={parameterQuestions}
      isNewScoringScaleOrg={isNewScoringScaleOrg}
    >
      {('items' in item &&
        item.items?.map((x) => {
          const xId =
            ('dataSource' in x && x.dataSource.id) ||
            ('policyParameter' in x && x.policyParameter.id) ||
            uuid()
          return renderItem({
            isChild: true,
            isExpanded: false,
            id: xId,
            item: x,
            handleOpenScorecardItem,
            handleToggleExpanded,
            context,
            parameterQuestions,
            isNewScoringScaleOrg,
          })
        })) ||
        undefined}
    </Item>
  )
}

const Scorecard = ({
  handleOpenScorecardItem,
  items,
  context,
  scoreAndCoverage,
  children,
  name,
  id: scorecardId,
  questionsWithAnswers,
}: Props) => {
  const mixpanel = useMixpanel()
  const [expanded, setExpanded] = useState<string[]>([])
  const { isNewScoringScaleOrg } = usePermissionContext()

  const flattenedItems = items?.map((x) => ('items' in x && x.items ? x.items : x)).flat()
  const flattenedEntries = items?.map((x) => x.scoreAndCoverage.dataEntries || []).flat()

  const dataPointsAmount = (flattenedEntries && flattenedEntries.length) || 0

  const handleToggleExpanded = (id: string) =>
    setExpanded(expanded.includes(id) ? expanded.filter((x) => x !== id) : [...expanded, id])

  const handleOpenScorecardItemWithTracking = (item: ScorecardItemV2ApiDto) => {
    mixpanel?.track('View scorecard item details', {
      'Page Title': document.title,
      'Event parameters': {
        Item: {
          name:
            ('dataSource' in item && item.dataSource.name) ||
            ('policyParameter' in item && item.policyParameter.name),
          id:
            ('dataSource' in item && item.dataSource.id) ||
            ('policyParameter' in item && item.policyParameter.id),
          coverage: item.scoreAndCoverage.coverage,
          score: item.scoreAndCoverage.score,
          dataEntryCount:
            (item.scoreAndCoverage.dataEntries && item.scoreAndCoverage.dataEntries.length) || 0,
        },
        'Scorecard ID': scorecardId,
        'Scorecard name': name,
        'Scorecard context': context === 'supplier' ? 'singleSupplier' : 'allSuppliers',
      },
      'Event category': 'Data',
    })

    handleOpenScorecardItem(item)
  }

  const tableHeaderSx = {
    fontWeight: 600,
    fontSize: 'body2.fontSize',
    textTransform: 'uppercase',
    marginTop: 0.5,
    marginBottom: 1,
  }

  return (
    <Paper
      maxHeight
      sx={{
        position: 'relative',
      }}
    >
      {children}
      <PaperContent sx={{ paddingTop: 0 }}>
        <Cards
          score={
            'min' in scoreAndCoverage.score
              ? getAverageScore(scoreAndCoverage.score.min, scoreAndCoverage.score.max)
              : undefined
          }
          dataCoverage={getDataCoverage(scoreAndCoverage.coverage)}
          itemsAmount={(flattenedItems && flattenedItems.length) || 0}
          dataEntriesAmount={dataPointsAmount}
        />
        <Grid container>
          <Grid item xs={isNewScoringScaleOrg ? 8 : 6}>
            <Typography sx={{ paddingLeft: 0.5, ...tableHeaderSx }}>Item</Typography>
          </Grid>
          {!isNewScoringScaleOrg && (
            <Grid item xs={3}>
              <Typography sx={{ ...tableHeaderSx }}>
                {context === 'supplier' ? 'Source' : 'Data coverage'}
              </Typography>
            </Grid>
          )}
          <Grid item xs={isNewScoringScaleOrg ? 4 : 3}>
            <Typography sx={{ paddingLeft: 0.5, ...tableHeaderSx }}>Performance</Typography>
          </Grid>
        </Grid>
        <div style={{ marginLeft: -8, marginRight: -8 }}>
          {items?.map((x) => {
            const id =
              ('dataSource' in x && x.dataSource.id) ||
              ('policyParameter' in x && x.policyParameter.id) ||
              ('items' in x && x.name) ||
              ''
            const isExpanded = expanded.includes(id)
            const parameterQuestions = questionsWithAnswers?.filter(
              (y) => 'policyParameterId' in y.mapping && id === y.mapping.policyParameterId
            )
            return (
              <div key={id}>
                {renderItem({
                  item: x,
                  id,
                  isChild: false,
                  handleOpenScorecardItem: handleOpenScorecardItemWithTracking,
                  handleToggleExpanded,
                  isExpanded,
                  context,
                  parameterQuestions,
                  isNewScoringScaleOrg,
                })}
              </div>
            )
          })}
        </div>
      </PaperContent>
    </Paper>
  )
}

export default memo(Scorecard)
