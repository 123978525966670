import apiClient from 'services/apiClient'
import { formatFiltersForApi } from 'views/buyer/Scorecards/helpers'
import {
  InsertSupplierContactIn,
  UpdateSupplierContactIn,
  UpdateSupplierIn,
  SuppliersLinkGlobalSupplierIn,
  SearchSupplierIn,
  SupplierStatus,
} from 'typescript-axios'
import { timeout } from 'workbox-core/_private'
import { SupplierFilterProps } from './queryKeys'

export const getSuppliers = async ({
  page,
  pageSize,
  filters,
  // TODO - api should accept undefined according to docs, but it doesn't
  supplierIds = [],
}: SupplierFilterProps & { page: number }) => {
  const supplierFilter = filters && filters.length > 0 ? formatFiltersForApi(filters) : undefined

  const { data } = await apiClient.postV2Suppliers({
    supplierIds,
    supplierFilter,
    paging: {
      page,
      pageSize,
    },
  })
  return data
}

export const createSupplier = ({
  name,
  countryCodeIso3,
  status,
  website,
  alias,
}: {
  name: string
  countryCodeIso3: string | undefined
  status?: SupplierStatus | undefined
  website: string | undefined
  alias?: string[] | undefined
}) => {
  const trimmedAliases = alias?.map((val) => val.trim()).filter((a) => a.length > 0)

  return apiClient.postV2SuppliersActionsAdd(name, countryCodeIso3, status, website, trimmedAliases)
}

export const updateSupplier = ({
  id,
  name,
  countryIso3Code,
  website,
  status,
  aliases,
}: { id: string } & UpdateSupplierIn) => {
  const trimmedAliases = aliases?.map((alias) => alias.trim()).filter((alias) => alias.length > 0)

  return apiClient.putV2SuppliersId(id, {
    name,
    countryIso3Code,
    website,
    status,
    aliases: trimmedAliases,
  })
}

export const linkGlobalSupplier = ({
  id,
  globalSupplierId,
}: { id: string } & SuppliersLinkGlobalSupplierIn) => {
  return apiClient.postV2SuppliersIdActionsLinkGlobalSupplier(id, { globalSupplierId })
}

export const unlinkGlobalSupplier = ({
  id,
  globalSupplierId,
}: { id: string } & SuppliersLinkGlobalSupplierIn) => {
  return apiClient.postV2SuppliersIdActionsUnlinkGlobalSupplier(id, globalSupplierId)
}

export const getSupplier = async ({ id }: { id: string }) => {
  const { data } = await apiClient.getV2SuppliersId(id)
  return data
}

export const getSupplierContacts = async ({
  page,
  pageSize,
  supplierId,
}: {
  page: number
  pageSize: number
  supplierId: string
}) => {
  const { data } = await apiClient.getV2SuppliersOrganizationsupplieridContacts(
    supplierId,
    page,
    pageSize
  )
  return data
}

export const getSupplierContact = async ({
  supplierId,
  id,
}: {
  supplierId: string
  id: string
}) => {
  const { data } = await apiClient.getV2SuppliersOrganizationsupplieridContactsId(supplierId, id)
  return data
}

export const createSupplierContact = async ({
  supplierId,
  firstName,
  lastName,
  emailAddress,
  languageTag,
}: InsertSupplierContactIn & { supplierId: string }) => {
  return apiClient.postV2SuppliersOrganizationsupplieridContacts(supplierId, {
    firstName,
    lastName,
    emailAddress,
    languageTag,
  })
}

export const setDefaultSupplierContact = async ({
  supplierId,
  id,
}: {
  supplierId: string
  id: string
}) => {
  return apiClient.postV2SuppliersOrganizationsupplieridContactsIdActionsMakeDefault(supplierId, id)
}

export const deleteSupplierContact = async ({
  supplierId,
  id,
}: {
  supplierId: string
  id: string
}) => {
  return apiClient.deleteV2SuppliersOrganizationsupplieridContactsId(supplierId, id)
}

export const updateSupplierContact = async ({
  supplierId,
  id,
  firstName,
  lastName,
  emailAddress,
  languageTag,
}: UpdateSupplierContactIn & { supplierId: string; id: string }) => {
  return apiClient.putV2SuppliersOrganizationsupplieridContactsId(supplierId, id, {
    firstName,
    lastName,
    emailAddress,
    languageTag,
  })
}

export const searchGlobalSuppliers = async ({
  supplierName,
  countryIso3Code,
  distance,
}: SearchSupplierIn) => {
  const { data } = await apiClient.postV2SuppliersActionsSearchGlobalSuppliers({
    supplierName,
    countryIso3Code,
    distance,
  })
  return data
}

export const globalSupplierSearch = async ({
  supplierName,
  countryIso3Code,
  distance,
}: SearchSupplierIn) => {
  return apiClient.postV2SuppliersActionsSearchGlobalSuppliers(
    {
      supplierName,
      countryIso3Code,
      distance,
    },
    { timeout: Infinity }
  )
}

export const getGlobalSupplier = async ({ ids }: { ids: string[] }) => {
  const { data } = await apiClient.getV2GlobalSuppliers(ids)
  return data
}

export const getSuppliersWithScoreAndCoverageByScorecardId = async (
  scorecardId: string,
  { page, pageSize, sortBy, sortOrder, filters }: SupplierFilterProps & { page: number }
) => {
  const { data } = await apiClient.postV2ScorecardsIdSupplierScoreAndCoverage(
    scorecardId as string,
    page,
    pageSize,
    {
      ...(sortBy && sortOrder
        ? { sortingChoice: { type: sortBy }, order: { type: sortOrder === 'asc' ? 'ASC' : 'DESC' } }
        : {}),
      filter: filters && filters.length > 0 ? formatFiltersForApi(filters) : undefined,
    }
  )

  return data
}
