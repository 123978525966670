import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  useQueries,
  UseInfiniteQueryOptions,
} from 'react-query'
import { getLikertScale, getAverageScore } from 'utils'
import {
  ResourceFoundScorecardV2ApiDto,
  ResourceListScorecardV2ApiDto,
  ResourceListSupplierScoreAndCoverageApiDto,
  PagingOut,
} from 'typescript-axios'
import { AxiosError } from 'axios'
import { getScorecards, getScorecard, getOverallScoreAcrossScorecards } from './api'
import queryKeys, { ScorecardFilters } from './queryKeys'

const { scorecardPages, singleScorecard, overallScoreAndCoverageForSuppliers } = queryKeys

export interface Test {
  paging: PagingOut
  pages: {
    data: ResourceListScorecardV2ApiDto[]
  }[]
}

export const useGetScorecards = (
  filters: ScorecardFilters,
  options?: UseInfiniteQueryOptions<
    ResourceListScorecardV2ApiDto,
    AxiosError,
    ResourceListScorecardV2ApiDto,
    ResourceListScorecardV2ApiDto,
    ReturnType<typeof scorecardPages>
  >
) => {
  return useInfiniteQuery(
    scorecardPages(filters),
    ({ pageParam = 1 }) =>
      getScorecards({
        page: pageParam,
        pageSize: filters.pageSize,
        supplierIds: filters.supplierIds,
        scorecardIds: filters.scorecardIds,
      }),
    {
      ...options,
      getNextPageParam: (lastPage) => lastPage.paging.nextPage,
      staleTime: 2000000,
      // select: (data) => {
      //   const flattenedScorecards = data.pages.flatMap((x) => x.data || [])
      //   const overallSupplierScoreForEachScorecard =
      //     flattenedScorecards.map((scorecard) => {
      //       const flatScorecardItems = scorecard.items?.flatMap((x) => {
      //         return 'items' in x && x.items ? [...x.items] : [x]
      //       })
      //       const supplierScoresAcrossScorecardItems =
      //         flatScorecardItems?.reduce((prev, curr) => {
      //           if (!('supplierScoresAndCoverages' in curr)) return prev
      //           const supplierScores = curr.supplierScoresAndCoverages?.map((x) => {
      //             return {
      //               supplierId: x.supplierId,
      //               v2Score:
      //                 'min' in x.scoreAndCoverageApiDto.score
      //                   ? (getLikertScale(
      //                       getAverageScore(
      //                         x.scoreAndCoverageApiDto.score.min,
      //                         x.scoreAndCoverageApiDto.score.max
      //                       ),
      //                       true,
      //                       true,
      //                       true
      //                     ) as number)
      //                   : 0,
      //             }
      //           })
      //           return {
      //             ...prev,
      //             ...supplierScores?.reduce((scorePrev, scoreCurr) => {
      //               return {
      //                 ...prev,
      //                 [scoreCurr.supplierId]:
      //                   scoreCurr.supplierId in prev &&
      //                   scorePrev[scoreCurr.supplierId] &&
      //                   scorePrev[scoreCurr.supplierId].length > 0
      //                     ? [...scorePrev[scoreCurr.supplierId], scoreCurr.v2Score]
      //                     : [scoreCurr.v2Score],
      //               }
      //             }, {} as { [key: string]: number[] }),
      //           }
      //         }, {} as { [key: string]: number[] }) || {}

      //       return Object.entries(supplierScoresAcrossScorecardItems).reduce(
      //         (prev, curr) => {
      //           const [supplierId, scores] = curr
      //           return {
      //             ...prev,
      //             [scorecard.id]: {
      //               ...prev[scorecard.id],
      //               [supplierId]:
      //                 scores.reduce((scorePrev, scoreCurr) => scorePrev + scoreCurr, 0) /
      //                 scores.length,
      //             },
      //           }
      //         },
      //         {} as {
      //           // Scorecard ID
      //           [key: string]: {
      //             // Supplier ID => avg score across items
      //             [key: string]: number
      //           }
      //         }
      //       )
      //     }) || []

      //   const test = overallSupplierScoreForEachScorecard.flatMap((scorecard) => {
      //     return Object.entries(scorecard).flatMap((x) => {
      //       const [scorecardId, supplierScore] = x
      //       return Object.entries(supplierScore).map((y) => {
      //         const [supplierId, score] = y
      //         return {
      //           scorecardId,
      //           supplierId,
      //           score,
      //         }
      //       })
      //     })
      //   })

      //   const flatSupplierScoresTest = test.reduce((prev, curr) => {
      //     const { supplierId, score } = curr
      //     return {
      //       ...prev,
      //       [supplierId]: supplierId in prev ? [...prev[supplierId], score] : [score],
      //     }
      //   }, {} as { [key: string]: number[] })

      //   const supplierAverageScoresAcrossScorecards = Object.entries(flatSupplierScoresTest).reduce(
      //     (prev, curr) => {
      //       const [supplierId, scores] = curr
      //       return {
      //         ...prev,
      //         [supplierId]: parseFloat(
      //           (
      //             scores.reduce((scorePrev, scoreCurr) => scorePrev + scoreCurr, 0) / scores.length
      //           ).toFixed(2)
      //         ),
      //       }
      //     },
      //     {} as { [key: string]: number }
      //   )

      //   return {
      //     ...data,
      //     v2OverallSupplierScores: supplierAverageScoresAcrossScorecards,
      //     pages: data.pages.map((page) => {
      //       const scorecardsWithNewScoreOnAllLevels = page.data?.map((scorecard) => {
      //         const scorecardItemMapWithNewScore =
      //           scorecard.items
      //             ?.flatMap((x) => {
      //               return 'items' in x && x.items ? [...x.items] : [x]
      //             })
      //             .reduce(
      //               (prev, curr) => {
      //                 const id =
      //                   ('dataSource' in curr && curr.dataSource.id) ||
      //                   ('policyParameter' in curr && curr.policyParameter.id) ||
      //                   undefined
      //                 if (!id) return prev
      //                 return {
      //                   ...prev,
      //                   [id]:
      //                     'supplierScoresAndCoverages' in curr
      //                       ? curr.supplierScoresAndCoverages?.map((x) => {
      //                           return 'min' in x.scoreAndCoverageApiDto.score
      //                             ? (getLikertScale(
      //                                 getAverageScore(
      //                                   x.scoreAndCoverageApiDto.score.min,
      //                                   x.scoreAndCoverageApiDto.score.max
      //                                 ),
      //                                 true,
      //                                 true,
      //                                 true
      //                               ) as number)
      //                             : 0
      //                         }) || []
      //                       : [],
      //                 }
      //               },
      //               {} as {
      //                 [key: string]: number[]
      //               }
      //             ) || {}
      //         const flatScoreValues = Object.values(scorecardItemMapWithNewScore).flatMap((x) => x)
      //         const overallAverageScoreAcrossItems =
      //           flatScoreValues.reduce((prev, curr) => prev + curr, 0) / flatScoreValues.length

      //         return {
      //           ...scorecard,
      //           scoreAndCoverage: {
      //             ...scorecard.scoreAndCoverage,
      //             score: {
      //               min: parseFloat(overallAverageScoreAcrossItems.toFixed(2)),
      //               max: parseFloat(overallAverageScoreAcrossItems.toFixed(2)),
      //               type: 'v2score',
      //             },
      //           },
      //           items: scorecard.items?.map((y) => {
      //             if ('items' in y && y.items) {
      //               const overallScoreForItems = y.items.reduce((prev, curr) => {
      //                 const itemId =
      //                   ('dataSource' in curr && curr.dataSource.id) ||
      //                   ('policyParameter' in curr && curr.policyParameter.id) ||
      //                   undefined
      //                 if (!itemId) return prev
      //                 const score = scorecardItemMapWithNewScore[itemId]
      //                 return (
      //                   prev +
      //                   score.reduce((scorePrev, scoreCurr) => scorePrev + scoreCurr, 0) /
      //                     score.length
      //                 )
      //               }, 0)
      //               return {
      //                 ...y,
      //                 scoreAndCoverage: {
      //                   ...y.scoreAndCoverage,
      //                   score: {
      //                     min: parseFloat(overallScoreForItems.toFixed(2)),
      //                     max: parseFloat(overallScoreForItems.toFixed(2)),
      //                     type: 'v2score',
      //                   },
      //                 },
      //                 items: y.items.map((z) => {
      //                   const id =
      //                     ('dataSource' in z && z.dataSource.id) ||
      //                     ('policyParameter' in z && z.policyParameter.id) ||
      //                     undefined
      //                   if (!id) return z
      //                   const itemScores = scorecardItemMapWithNewScore[id]
      //                   return {
      //                     ...z,
      //                     scoreAndCoverage: {
      //                       ...z.scoreAndCoverage,
      //                       score: {
      //                         min: parseFloat(
      //                           (
      //                             itemScores.reduce((prev, curr) => prev + curr, 0) /
      //                             itemScores.length
      //                           ).toFixed(2)
      //                         ),
      //                         max: parseFloat(
      //                           (
      //                             itemScores.reduce((prev, curr) => prev + curr, 0) /
      //                             itemScores.length
      //                           ).toFixed(2)
      //                         ),
      //                         type: 'v2score',
      //                       },
      //                     },
      //                   }
      //                 }),
      //               }
      //             }

      //             const id =
      //               ('dataSource' in y && y.dataSource.id) ||
      //               ('policyParameter' in y && y.policyParameter.id) ||
      //               undefined
      //             if (!id) return y
      //             const itemScores = scorecardItemMapWithNewScore[id]
      //             return {
      //               ...y,
      //               scoreAndCoverage: {
      //                 ...y.scoreAndCoverage,
      //                 score: {
      //                   min: parseFloat(
      //                     (
      //                       itemScores.reduce((prev, curr) => prev + curr, 0) / itemScores.length
      //                     ).toFixed(2)
      //                   ),
      //                   max: parseFloat(
      //                     (
      //                       itemScores.reduce((prev, curr) => prev + curr, 0) / itemScores.length
      //                     ).toFixed(2)
      //                   ),
      //                   type: 'v2score',
      //                 },
      //               },
      //             }
      //           }),
      //         }
      //       })

      //       return {
      //         ...page,
      //         data: scorecardsWithNewScoreOnAllLevels,
      //       }
      //     }),
      //   }
      // },
    }
  )
}

export const useGetScorecard = (
  scorecardId: string,
  supplierIdd?: string,
  options?: UseQueryOptions<
    ResourceFoundScorecardV2ApiDto,
    AxiosError,
    ResourceFoundScorecardV2ApiDto,
    ReturnType<typeof singleScorecard>
  >
) => {
  return useQuery(
    singleScorecard(scorecardId, supplierIdd),
    () => getScorecard({ id: scorecardId, supplierId: supplierIdd }),
    {
      ...options,
      // select: (data) => {
      //   // const flatScorecardItems = data.data?.items?.flatMap((x) => {
      //   //   return 'items' in x && x.items ? [...x.items] : [x]
      //   // })
      //   // const supplierScoresAcrossScorecardItems =
      //   //   flatScorecardItems?.reduce((prev, curr) => {
      //   //     if (!('supplierScoresAndCoverages' in curr)) return prev
      //   //     const supplierScores = curr.supplierScoresAndCoverages?.map((x) => {
      //   //       return {
      //   //         supplierId: x.supplierId,
      //   //         v2Score:
      //   //           'min' in x.scoreAndCoverageApiDto.score
      //   //             ? (getLikertScale(
      //   //                 getAverageScore(
      //   //                   x.scoreAndCoverageApiDto.score.min,
      //   //                   x.scoreAndCoverageApiDto.score.max
      //   //                 ),
      //   //                 true,
      //   //                 true,
      //   //                 true
      //   //               ) as number)
      //   //             : 0,
      //   //       }
      //   //     })
      //   //     return {
      //   //       ...prev,
      //   //       ...supplierScores?.reduce((scorePrev, scoreCurr) => {
      //   //         return {
      //   //           ...prev,
      //   //           [scoreCurr.supplierId]:
      //   //             scoreCurr.supplierId in prev &&
      //   //             scorePrev[scoreCurr.supplierId] &&
      //   //             scorePrev[scoreCurr.supplierId].length > 0
      //   //               ? [...scorePrev[scoreCurr.supplierId], scoreCurr.v2Score]
      //   //               : [scoreCurr.v2Score],
      //   //         }
      //   //       }, {} as { [key: string]: number[] }),
      //   //     }
      //   //   }, {} as { [key: string]: number[] }) || {}

      //   // const test = Object.entries(supplierScoresAcrossScorecardItems).reduce(
      //   //   (prev, curr) => {
      //   //     const [supplierId, scores] = curr
      //   //     return {
      //   //       ...prev,
      //   //       [supplierId]:
      //   //         scores.reduce((scorePrev, scoreCurr) => scorePrev + scoreCurr, 0) / scores.length,
      //   //     }
      //   //   },
      //   //   {} as {
      //   //     [key: string]: number
      //   //   }
      //   // )

      //   // console.log(test)

      //   const scorecardItemMapWithNewScore =
      //     data.data.items
      //       ?.flatMap((x) => {
      //         return 'items' in x && x.items ? [...x.items] : [x]
      //       })
      //       .reduce(
      //         (prev, curr) => {
      //           const id =
      //             ('dataSource' in curr && curr.dataSource.id) ||
      //             ('policyParameter' in curr && curr.policyParameter.id) ||
      //             undefined
      //           if (!id) return prev
      //           return {
      //             ...prev,
      //             [id]:
      //               'supplierScoresAndCoverages' in curr
      //                 ? curr.supplierScoresAndCoverages?.map((x) => {
      //                     return 'min' in x.scoreAndCoverageApiDto.score
      //                       ? (getLikertScale(
      //                           getAverageScore(
      //                             x.scoreAndCoverageApiDto.score.min,
      //                             x.scoreAndCoverageApiDto.score.max
      //                           ),
      //                           true,
      //                           true,
      //                           true
      //                         ) as number)
      //                       : 0
      //                   }) || []
      //                 : [],
      //           }
      //         },
      //         {} as {
      //           [key: string]: number[]
      //         }
      //       ) || {}
      //   const flatScoreValues = Object.values(scorecardItemMapWithNewScore).flatMap((x) => x)
      //   const overallAverageScoreAcrossItems =
      //     flatScoreValues.reduce((prev, curr) => prev + curr, 0) / flatScoreValues.length

      //   return {
      //     data: {
      //       ...data.data,
      //       scoreAndCoverage: {
      //         ...data.data.scoreAndCoverage,
      //         score: {
      //           min: parseFloat(overallAverageScoreAcrossItems.toFixed(2)),
      //           max: parseFloat(overallAverageScoreAcrossItems.toFixed(2)),
      //           type: 'v2score',
      //         },
      //       },
      //       items: data.data.items?.map((y) => {
      //         if ('items' in y && y.items) {
      //           const overallScoreForItems = y.items.reduce((prev, curr) => {
      //             const itemId =
      //               ('dataSource' in curr && curr.dataSource.id) ||
      //               ('policyParameter' in curr && curr.policyParameter.id) ||
      //               undefined
      //             if (!itemId) return prev
      //             const score = scorecardItemMapWithNewScore[itemId]
      //             return (
      //               prev +
      //               score.reduce((scorePrev, scoreCurr) => scorePrev + scoreCurr, 0) / score.length
      //             )
      //           }, 0)
      //           return {
      //             ...y,
      //             scoreAndCoverage: {
      //               ...y.scoreAndCoverage,
      //               score: {
      //                 min: parseFloat(overallScoreForItems.toFixed(2)),
      //                 max: parseFloat(overallScoreForItems.toFixed(2)),
      //                 type: 'v2score',
      //               },
      //             },
      //             items: y.items.map((z) => {
      //               const id =
      //                 ('dataSource' in z && z.dataSource.id) ||
      //                 ('policyParameter' in z && z.policyParameter.id) ||
      //                 undefined
      //               if (!id) return z
      //               const itemScores = scorecardItemMapWithNewScore[id]
      //               return {
      //                 ...z,
      //                 scoreAndCoverage: {
      //                   ...z.scoreAndCoverage,
      //                   score: {
      //                     min: parseFloat(
      //                       (
      //                         itemScores.reduce((prev, curr) => prev + curr, 0) / itemScores.length
      //                       ).toFixed(2)
      //                     ),
      //                     max: parseFloat(
      //                       (
      //                         itemScores.reduce((prev, curr) => prev + curr, 0) / itemScores.length
      //                       ).toFixed(2)
      //                     ),
      //                     type: 'v2score',
      //                   },
      //                 },
      //                 // supplierScoresAndCoverages:
      //                 //   ('supplierScoresAndCoverages' in z &&
      //                 //     z.supplierScoresAndCoverages?.map((x) => {
      //                 //       return {
      //                 //         ...x,
      //                 //         scoreAndCoverageApiDto: {
      //                 //           ...x.scoreAndCoverageApiDto,
      //                 //           score: {
      //                 //             min: parseFloat(
      //                 //               (
      //                 //                 itemScores.reduce((prev, curr) => prev + curr, 0) /
      //                 //                 itemScores.length
      //                 //               ).toFixed(2)
      //                 //             ),
      //                 //             max: parseFloat(
      //                 //               (
      //                 //                 itemScores.reduce((prev, curr) => prev + curr, 0) /
      //                 //                 itemScores.length
      //                 //               ).toFixed(2)
      //                 //             ),
      //                 //           },
      //                 //         },
      //                 //       }
      //                 //     })) ||
      //                 //   undefined,
      //               }
      //             }),
      //           }
      //         }

      //         const id =
      //           ('dataSource' in y && y.dataSource.id) ||
      //           ('policyParameter' in y && y.policyParameter.id) ||
      //           undefined
      //         if (!id) return y
      //         const itemScores = scorecardItemMapWithNewScore[id]
      //         return {
      //           ...y,
      //           scoreAndCoverage: {
      //             ...y.scoreAndCoverage,
      //             score: {
      //               min: parseFloat(
      //                 (
      //                   itemScores.reduce((prev, curr) => prev + curr, 0) / itemScores.length
      //                 ).toFixed(2)
      //               ),
      //               max: parseFloat(
      //                 (
      //                   itemScores.reduce((prev, curr) => prev + curr, 0) / itemScores.length
      //                 ).toFixed(2)
      //               ),
      //               type: 'v2score',
      //             },
      //           },
      //         }
      //       }),
      //     },
      //   }
      // },
    }
  )
}

export const useGetMultipleScorecards = (
  scorecards: { id: string; supplierId?: string; enabled?: boolean }[]
) => {
  return useQueries(
    scorecards.map((x) => {
      return {
        queryKey: singleScorecard(x.id, x.supplierId),
        queryFn: () => getScorecard({ id: x.id, supplierId: x.supplierId }),
        enabled: x.enabled,
        staleTime: 250000,
      }
    })
  )
}

export const useGetOverallScoreAndCoverage = (
  supplierIds: string[],
  options?: UseQueryOptions<
    ResourceListSupplierScoreAndCoverageApiDto,
    AxiosError,
    ResourceListSupplierScoreAndCoverageApiDto,
    ReturnType<typeof overallScoreAndCoverageForSuppliers>
  >
) => {
  return useQuery(
    overallScoreAndCoverageForSuppliers({ supplierIds }),
    () => getOverallScoreAcrossScorecards({ supplierIds }),
    {
      ...options,
    }
  )
}
