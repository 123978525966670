import { memo } from 'react'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'
import {
  ListItem,
  ListItemText,
  Typography,
  alpha,
  Box,
  Collapse,
  Grid,
  AvatarGroup,
  Tooltip,
  Avatar,
} from '@mui/material'
import {
  ChevronRightRounded as ChevronRightIcon,
  SendOutlined as DataRequestIcon,
} from '@mui/icons-material'
import config from 'constants/config'
import staticDataSourceMetaData from 'hooks/dataSources/staticMetaData'
import { getParameterCategory, getAverageScore, getDataCoverage } from 'utils'
import { Donut } from 'components'
import InlineDataSourceAvatar from '../InlineDataSourceAvatar'
import ScorecardItemAvatar from '../ScorecardItemAvatar'
import { ItemProps } from '../../index'

const PREFIX = 'index'

const classes = {
  listItem: `${PREFIX}-listItem`,
  listItemChild: `${PREFIX}-listItemChild`,
  listItemText: `${PREFIX}-listItemText`,
  container: `${PREFIX}-container`,
  expandedContainer: `${PREFIX}-expandedContainer`,
  flex: `${PREFIX}-flex`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.listItem}`]: {
    paddingTop: 6,
    paddingBottom: 6,
    minHeight: 54,
    paddingLeft: theme.spacing(),
    position: 'relative',
  },

  [`& .${classes.listItemChild}`]: {
    paddingLeft: theme.spacing(5),
  },

  [`& .${classes.listItemText}`]: {
    margin: 0,
    zIndex: 1,
  },

  [`& .${classes.container}`]: {
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.04),
    },
  },

  [`& .${classes.expandedContainer}`]: {
    backgroundColor: alpha(theme.palette.common.black, 0.02),
  },

  [`& .${classes.flex}`]: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: -1,
  },
}))

interface Props extends ItemProps {
  children?: React.ReactNode
}

const Item = ({
  handleOpenScorecardItem,
  handleToggleExpanded,
  item,
  isChild,
  children,
  isExpanded,
  id,
  context,
  parameterQuestions,
  isNewScoringScaleOrg,
}: Props) => {
  const handleClick = () => (children ? handleToggleExpanded(id) : handleOpenScorecardItem(item))

  const containerClassName = classNames([classes.container], {
    [classes.expandedContainer]: isExpanded,
  })

  const listItemClassName = classNames([classes.listItem], {
    [classes.listItemChild]: isChild,
  })

  const dataSource = ('dataSource' in item && item.dataSource) || undefined
  const parameter = ('policyParameter' in item && item.policyParameter) || undefined
  const group = ('items' in item && item) || undefined

  const parameterTopLevelCategory = (parameter && getParameterCategory(parameter)) || undefined

  const name = dataSource?.name || parameter?.name || group?.name || ''
  const secondary =
    (dataSource?.dataSourceType && `${dataSource?.dataSourceType} data source`) ||
    (parameterTopLevelCategory && `${parameterTopLevelCategory.name} parameter`) ||
    (group?.items && `${group.items.length} items`) ||
    ''
  const avatarType = (parameter && 'parameter') || dataSource?.dataSourceType || 'group'
  const logoUrl =
    (dataSource?.id &&
      dataSource.id in staticDataSourceMetaData &&
      staticDataSourceMetaData[dataSource.id].imgUrl) ||
    undefined

  const performanceScore =
    'min' in item.scoreAndCoverage.score
      ? getAverageScore(item.scoreAndCoverage.score.min, item.scoreAndCoverage.score.max)
      : undefined

  const parameterQuestionsWithAnswers =
    (parameterQuestions &&
      parameterQuestions.length > 0 &&
      parameterQuestions.filter((x) => x.answers && x.answers.length > 0)) ||
    []

  return (
    <Root>
      <Box onClick={handleClick} className={containerClassName}>
        <Grid container>
          <Grid item xs={isNewScoringScaleOrg ? 8 : 6}>
            <ListItem className={listItemClassName}>
              <ScorecardItemAvatar
                logoUrl={
                  logoUrl ? `${config.largeMediaUrl}${logoUrl}?nf_resize=fit&h=24` : undefined
                }
                type={avatarType.toLowerCase()}
                category={parameterTopLevelCategory?.name || undefined}
                isExpanded={isExpanded}
              />
              <ListItemText
                className={classes.listItemText}
                disableTypography
                primary={
                  <Typography component="span">
                    <strong>{name}</strong>
                  </Typography>
                }
                secondary={
                  <Typography
                    component="span"
                    style={{ display: 'flex' }}
                    variant="body2"
                    color="textSecondary"
                  >
                    {secondary}
                  </Typography>
                }
              />
            </ListItem>
          </Grid>
          {((!context && !isNewScoringScaleOrg) ||
            (context === 'supplier' && !isNewScoringScaleOrg) ||
            (context === 'suppliers' && !isNewScoringScaleOrg)) && (
            <Grid item xs={3} className={classes.flex}>
              {!context || context === 'suppliers'
                ? !isNewScoringScaleOrg && (
                    <Donut
                      value={getDataCoverage(item.scoreAndCoverage.coverage)}
                      withBrackets={false}
                      marginLeft="-6px"
                    />
                  )
                : (item.scoreAndCoverage.dataEntries &&
                    item.scoreAndCoverage.dataEntries.length > 0 && (
                      <>
                        <AvatarGroup max={4}>
                          {item.scoreAndCoverage.dataEntries.slice(0, 4).map((x) => {
                            return <InlineDataSourceAvatar key={x} id={x} />
                          })}
                          {parameterQuestionsWithAnswers.length > 0 && (
                            <Tooltip
                              title={parameterQuestionsWithAnswers.map((y) => (
                                <Box key={y.definition.id}>- {y.definition.title}</Box>
                              ))}
                            >
                              <Avatar
                                sx={[
                                  {
                                    backgroundColor: 'backgroundDark',
                                    border: (theme) => `1px solid ${theme.palette.common.white}`,
                                    width: 23,
                                    height: 23,
                                    boxShadow: 'none',
                                    marginLeft: '-3px',
                                    cursor: 'pointer',
                                    transition: (theme) =>
                                      theme.transitions.create('opacity', {
                                        easing: theme.transitions.easing.sharp,
                                        duration: theme.transitions.duration.leavingScreen,
                                      }),
                                    '&:hover': {
                                      opacity: 0.7,
                                    },
                                  },
                                ]}
                              >
                                <DataRequestIcon
                                  sx={{ color: 'common.black', width: 16, height: 16 }}
                                />
                              </Avatar>
                            </Tooltip>
                          )}
                        </AvatarGroup>
                        {item.scoreAndCoverage.dataEntries.length > 4 && (
                          <Typography variant="body2" color="textSecondary">
                            +{item.scoreAndCoverage.dataEntries.length - 4}
                          </Typography>
                        )}
                      </>
                    )) || (
                    <Typography variant="body2" color="textSecondary">
                      {parameterQuestionsWithAnswers.length > 0 ? (
                        <Tooltip
                          title={parameterQuestionsWithAnswers.map((y) => (
                            <Box key={y.definition.id}>- {y.definition.title}</Box>
                          ))}
                        >
                          <Avatar
                            sx={[
                              {
                                backgroundColor: 'backgroundDark',
                                border: (theme) => `1px solid ${theme.palette.common.white}`,
                                width: 23,
                                height: 23,
                                boxShadow: 'none',
                                marginLeft: '-3px',
                                cursor: 'pointer',
                                transition: (theme) =>
                                  theme.transitions.create('opacity', {
                                    easing: theme.transitions.easing.sharp,
                                    duration: theme.transitions.duration.leavingScreen,
                                  }),
                                '&:hover': {
                                  opacity: 0.7,
                                },
                              },
                            ]}
                          >
                            <DataRequestIcon
                              sx={{ color: 'common.black', width: 16, height: 16 }}
                            />
                          </Avatar>
                        </Tooltip>
                      ) : (
                        <i>N/A</i>
                      )}
                    </Typography>
                  )}
            </Grid>
          )}
          <Grid
            item
            xs={isNewScoringScaleOrg ? 4 : 3}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Donut
              value={performanceScore}
              marginLeft="-6px"
              gaugeMarginTop={-10}
              centerTextSx={{
                pt: '7px',
              }}
            />
            <ChevronRightIcon style={{ marginRight: 8, opacity: group ? 0 : 1 }} />
          </Grid>
        </Grid>
      </Box>
      <Collapse in={isExpanded}>
        <div>{children}</div>
      </Collapse>
    </Root>
  )
}

export default memo(Item)
